import React from 'react'
import PropTypes from 'prop-types'

import { thumbsDown, thumbsUp, volume } from './icons'

import './style.css'

function CardResult({ topElement, domain, tld, description, ...props }) {
  return (
    <div className='cr-container'>
      {!!topElement && <div className='cr-media'>{topElement}</div>}
      <h3 className='cr-header'>
        <span className='cr-domain'>{domain}</span>
        <span className='cr-tld'>.{tld}</span>
      </h3>
      <p className='cr-subhead'>{description}</p>
      <div className='cr-divider' />
      <div className='cr-actions'>
        <button className='btn'>
          <span className='btn__content' tabIndex='-1'>
            {thumbsUp}
          </span>
        </button>
        <button className='btn'>
          <span className='btn__content' tabIndex='-1'>
            {thumbsDown}
          </span>
        </button>
        <button className='btn'>
          <span className='btn__content' tabIndex='-1'>
            {volume}
          </span>
        </button>
      </div>
    </div>
  )
}

CardResult.propTypes = {
  topElement: PropTypes.any,
  domain: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  tld: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}
CardResult.defaultProps = {
  topElement: null,
  description: ''
}

export default CardResult
