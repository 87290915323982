import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import App from './App'
import Domain from './App/Domain'
import DomainAllTypes from './App/DomainAllTypes'
import CardResultsSample from './App/CardResultsSample'
import * as serviceWorker from './serviceWorker'

import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route component={App} path='/' exact />
        <Route component={DomainAllTypes} path='/domain/:domain/:iconIndex' />
        <Route component={Domain} path='/domain/:domain' />
        <Route component={CardResultsSample} path='/results' />
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
