import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { like, dislike, listen, likeFill, dislikeFill } from './icons'
import styles from './CardResult.module.css'

function CardResult({ domain, tld, ...props }) {
  const children = props.children

  const [isSelected, setSelected] = useState(false)
  const [isLiked, setLike] = useState(false)
  const [isDisliked, setDislike] = useState(false)

  function _onSelect(e) {
    setSelected(e.target.checked)
  }
  function _handleEnter(e) {
    if (e.key === 'Enter') {
      setSelected(!isSelected)
    }
  }
  function _like() {
    setLike(!isLiked)
    if (isDisliked) {
      setDislike(false)
    }
  }
  function _dislike() {
    setDislike(!isDisliked)
    if (isLiked) {
      setLike(false)
    }
  }

  return (
    <div
      className={[
        styles.mainContainer,
        isSelected ? styles.mainContainerSelected : ''
      ].join(' ')}
      tabIndex='0'
      // onMouseLeave={(e) => e.currentTarget.blur()}
    >
      <div className={styles.actionsContainer}>
        <div className={styles.selectContainer}>
          <div className={[styles.selectField, styles['enter-left']].join(' ')}>
            <label
              className={[styles.label, styles.btn].join(' ')}
              tabIndex='0'
              onKeyDown={_handleEnter}
            >
              <span className={styles.hidden}>select</span>
              <input
                type='checkbox'
                checked={isSelected}
                onChange={_onSelect}
                tabIndex={-2}
              />
              <span
                className={[styles.checkmark, styles.btn_content].join(' ')}
                tabIndex='-1'
              />
            </label>
          </div>
        </div>
        <h3 className={[styles.domain, styles['enter-center']].join(' ')}>
          <span className={styles.domainName}>{domain}</span>
          <span className={styles.tld}>.{tld}</span>
        </h3>
        <div
          className={[styles.divider, styles['horizontal-divider']].join(' ')}
        />
        <div className={styles.likeContainer}>
          <div className={[styles.listen, styles['enter-center']].join(' ')}>
            <button className={[styles.button, styles.btn].join(' ')}>
              <span className={styles.hidden}>listen</span>
              <span className={styles['btn_content']} tabIndex='-1'>
                {listen}
              </span>
            </button>
          </div>
          <button
            className={[
              styles.button,
              isLiked ? '' : styles.like,
              styles.btn,
              styles['enter-left']
            ].join(' ')}
            onClick={_like}
          >
            <span className={styles.hidden}>like</span>
            <span className={styles['btn_content']} tabIndex='-1'>
              {isLiked ? likeFill : like}
            </span>
          </button>
          <div
            className={[styles.divider, styles['vertical-divider']].join(' ')}
          />
          <button
            className={[styles.button, styles.btn, styles['enter-right']].join(
              ' '
            )}
            onClick={_dislike}
          >
            <span className={styles.hidden}>dislike</span>
            <span className={styles['btn_content']} tabIndex='-1'>
              {isDisliked ? dislikeFill : dislike}
            </span>
          </button>
        </div>
      </div>
      {children}
    </div>
  )
}

CardResult.propTypes = {
  domain: PropTypes.string.isRequired,
  tld: PropTypes.string.isRequired,
  children: PropTypes.any
}

export default CardResult
