import React from 'react'

import CardResult from '../../components/CardResult'
import CardResult2 from '../../components/CardResult2'
import CardResult3 from '../../components/CardResult3'
import Logo from '../../components/Logo'
import { domains } from '../../contents/domains'
import { types } from '../../contents/iconTypes'
import { icons } from '../../contents/icons'

import './style.css'

const data = [domains[0], domains[1], domains[2]]

function Results() {
  return (
    <>
      <h2 className='sample-title'>Sample one</h2>
      <div className='results-app'>
        {data.map((domain, index) => (
          <div className='result' key={index}>
            <CardResult
              topElement={<div className='top-element'>{domain}.com</div>}
              domain={domain}
              tld='com'
              description='description'
            />
          </div>
        ))}
      </div>
      <h2 className='sample-title'>Sample two</h2>
      <div className='results-app'>
        {data.map((domain, index) => (
          <div className='result2' key={index}>
            <CardResult2 domain={domain} tld='com' key={index}>
              <Logo
                icon={icons[index]}
                description='description'
                title={domain}
                type={types[index + 10]}
                size={350}
                hasHover={false}
              />
            </CardResult2>
          </div>
        ))}
      </div>
      <h2 className='sample-title'>Sample three</h2>
      <div
        className='results-app'
        style={{
          paddingBottom: ' 200px'
        }}
      >
        {data.map((domain, index) => (
          <div className='result2' key={index}>
            <CardResult3 domain={domain} tld='com' key={index}>
              <Logo
                icon={icons[index]}
                description='description'
                title={domain}
                type={types[index + 10]}
                size={350}
                hasHover={false}
              />
            </CardResult3>
          </div>
        ))}
      </div>
    </>
  )
}

export default Results
