import React from 'react'
import PropTypes from 'prop-types'

import Logo from '../../components/Logo'
import Back, { Empty } from '../../components/Back'
import { types } from '../../contents/iconTypes'
import { icons } from '../../contents/icons'
import LogoWrapper from '../../components/LogoWrapper'

import './style.css'

function DomainAllTypes({ ...props }) {
  const { domain, iconIndex } = props.match.params
  return (
    <div className='dat-container'>
      <div className='dat-title-container'>
        <Back />
        <h2 className='dat-title'>{domain}</h2>
        <Empty />
      </div>
      <div className='logo-container'>
        {types.map((type, index) => {
          return (
            <div className='logo' key={index}>
              <LogoWrapper>
                <Logo
                  description='description'
                  icon={icons[iconIndex]}
                  title={domain}
                  type={type}
                />
              </LogoWrapper>
            </div>
          )
        })}
      </div>
    </div>
  )
}

DomainAllTypes.propTypes = {
  match: PropTypes.object
}

export default DomainAllTypes
