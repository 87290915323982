import React from 'react'
import PropTypes from 'prop-types'

import styles from './Checkbox.module.css'

function Checkbox({ handleEnter, onCheckboxChange, isSelected }) {
  return (
    <label
      className={[styles.label, styles.btn].join(' ')}
      tabIndex='0'
      onKeyDown={handleEnter}
    >
      <span className={styles.hidden}>select</span>
      <input
        type='checkbox'
        checked={isSelected}
        onChange={onCheckboxChange}
        tabIndex={-2}
      />
      <span
        className={[styles.checkmark, styles.btn_content].join(' ')}
        tabIndex='-1'
      />
    </label>
  )
}
Checkbox.propTypes = {
  handleEnter: PropTypes.func,
  onCheckboxChange: PropTypes.func,
  isSelected: PropTypes.bool
}

export default Checkbox
