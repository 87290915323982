export const types = [
  {
    logoLink:
      'https://www.canva.com/design/DAD8ZnMmdmM/MdSqk3rB9eZRthpYIxO1bg/edit?category=tACZCvjI6mE',
    fontFamily: 'Radley',
    fontSize: 41.9,
    bold: null,
    italic: null,
    color: '#253140',
    backgroundColor: '#ffffff',
    iconColor: '#ec1c25',
    iconPosition: 'top',
    subFont: null,
    subFontSize: null
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8ZjDksos/EWA17xn8rvlx9UoZVEGCtQ/edit?category=tACZCvjI6mE',
    fontFamily: 'Playfair Display',
    fontSize: 21,
    bold: null,
    italic: null,
    color: '#5a393e',
    backgroundColor: '#fbede0',
    iconColor: '#5a393e',
    iconPosition: 'top',
    subFont: 'Montserrat',
    subFontSize: 6.5
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8Zl1rbbc/bwoj4Y69yg5KDTzlZEPinA/edit?category=tACZCvjI6mE',
    fontFamily: 'Alegreya',
    fontSize: 24.9,
    bold: null,
    italic: null,
    color: '#0d232a',
    backgroundColor: '#f8f8f8',
    iconColor: '#0d232a',
    iconPosition: 'top',
    subFont: 'Montserrat',
    subFontSize: 6
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8ZswPsFs/KiHl0dWn_fVRgXQ-WT830g/edit?category=tACZCvjI6mE',
    fontFamily: 'Aileron Heavy',
    fontSize: 44.7,
    bold: true,
    italic: true,
    color: '#791a00',
    backgroundColor: '#f66c13',
    iconColor: '#791a00',
    iconPosition: null,
    subFont: null,
    subFontSize: null
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8ZtJtbMY/ucV3mZP8KTrEnTYUb7X_7g/edit?category=tACZCvjI6mE',
    fontFamily: 'Poppins',
    fontSize: 28,
    bold: true,
    italic: null,
    color: '#ffffff',
    backgroundColor: '#002858',
    iconColor: '#005fd7',
    iconPosition: 'top',
    subFont: 'Poppins',
    subFontSize: 8
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8Z1sc5c8/QynvY1xXD-AJ8rvwSKnt6g/edit?category=tACZCvjI6mE',
    fontFamily: 'Horizon',
    fontSize: 26.5,
    bold: null,
    italic: null,
    color: '#000000',
    backgroundColor: '#ffffff',
    iconColor: '#36ab9c',
    iconPosition: 'top',
    subFont: null,
    subFontSize: null
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8Z-kL_Iw/yyO-FahvJSwBYpCC_SSdyQ/edit?category=tACZCvjI6mE',
    fontFamily: 'Vollkorn',
    fontSize: 28,
    bold: null,
    italic: null,
    color: '#e0cbb6',
    backgroundColor: '#304543',
    iconColor: '#ffffff',
    iconPosition: 'top',
    subFont: null,
    subFontSize: null
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8ZzTQ41Q/59qucAAiA8x8ZcuZh472vQ/edit?category=tACZCvjI6mE',
    fontFamily: 'Norwester',
    fontSize: 44.7,
    bold: null,
    italic: null,
    color: '#df9667',
    backgroundColor: '#fff8f1',
    iconColor: null,
    iconPosition: null,
    subFont: 'Pacifico',
    subFontSize: 24
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8Z_zh-n4/54Deg33voCd_sJgWvHDycg/edit?category=tACZCvjI6mE',
    fontFamily: 'Forum',
    fontSize: 29.9,
    bold: null,
    italic: null,
    color: '#964d29',
    backgroundColor: '#edc79b',
    iconColor: '#964d29',
    iconPosition: 'top',
    subFont: 'Raleway',
    subFontSize: 9.6
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8Z9sCcaU/rs3YLpKJGQZ8YA84c7tw1w/edit?category=tACZCvjI6mE',
    fontFamily: 'Charmonman',
    fontSize: 68,
    bold: true,
    italic: null,
    color: '#a47f4c',
    backgroundColor: '#eee7de',
    iconColor: '#3b3a37',
    iconPosition: 'top',
    subFont: 'Prompt',
    subFontSize: 15.2
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8Z_8BXWw/mO6pxb6Lsz9KcAnYrkmLzw/edit?category=tACZCvjI6mE',
    fontFamily: 'Glacial Indifference',
    fontSize: 34.8,
    bold: true,
    italic: null,
    color: '#ffffff',
    backgroundColor: '#262262',
    iconColor: '#f7941e',
    iconPosition: 'top',
    subFont: 'Glacial Indifference',
    subFontSize: 13.2
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8Z6zZxmg/JVyTfgEMwJBZ2yGf6REAEA/edit?category=tACZCvjI6mE',
    fontFamily: 'Moontime',
    fontSize: 49.6,
    bold: null,
    italic: null,
    color: '#ffffff',
    backgroundColor: '#8fa382',
    iconColor: null,
    iconPosition: null,
    subFont: 'Glacial Indifference',
    subFontSize: 7
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8Zy46nKc/4e6rjVDdiUfUJinfRIEH_Q/edit?category=tACZCvjI6mE',
    fontFamily: 'Redley',
    fontSize: 32,
    bold: null,
    italic: null,
    color: '#272023',
    backgroundColor: '#ffffff',
    iconColor: '#fff3ea',
    iconPosition: 'center',
    subFont: 'Raleway',
    subFontSize: 14
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8Z1BfUeM/-AaPaxMH0hV4MC9X4u6EQQ/edit?category=tACZCvjI6mE',
    fontFamily: 'Mirza',
    fontSize: 28,
    bold: true,
    italic: null,
    color: '#d4d4d4',
    backgroundColor: '#253932',
    iconColor: null,
    iconPosition: null,
    subFont: 'Mr Dafoe',
    subFontSize: 16
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8Z3c5brQ/xZTnnRDooOxI0AmaGASSaQ/edit?category=tACZCvjI6mE',
    fontFamily: 'Redley',
    fontSize: 30.4,
    bold: null,
    italic: null,
    color: '#204d76',
    backgroundColor: '#ffffff',
    iconColor: null,
    iconPosition: null,
    subFont: 'Montserrat',
    subFontSize: 8.5
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8Z0tDu60/QrF6XC6MFPHTJ_Dy0fmKmA/edit?category=tACZCvjI6mE',
    fontFamily: 'Montserrat',
    fontSize: 26,
    bold: true,
    italic: null,
    color: '#4c4d51',
    backgroundColor: '#c7e5e3',
    iconColor: '#ffffff',
    iconPosition: 'top',
    subFont: 'Inria serif',
    subFontSize: 14.6
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8Z9KFJiE/4OgFD17IoYR_MIhd-px3Rg/edit?category=tACZCvjI6mE',
    fontFamily: 'Montserrat',
    fontSize: 42,
    bold: null,
    italic: null,
    color: '#0e5679',
    backgroundColor: '#fafafa',
    iconColor: '#ed938f',
    iconPosition: 'top',
    subFont: 'Montserrat',
    subFontSize: 12
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8aFgPiPc/jTU-xiUUhsAuQfjov1CS_A/edit?category=tACZCvjI6mE',
    fontFamily: 'Kollektif',
    fontSize: 44.7,
    bold: true,
    italic: true,
    color: '#ffffff',
    backgroundColor: '#073f54',
    iconColor: '#86fbff',
    iconPosition: 'left',
    subFont: 'DM Sans',
    subFontSize: 12
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8aB3Za6I/aWILJWq0z3M6ExNR2W4JYA/edit?category=tACZCvjI6mE',
    fontFamily: 'Bryndan Write',
    fontSize: 49.1,
    bold: null,
    italic: null,
    color: '#ffffff',
    backgroundColor: '#0000ff',
    iconColor: '#ffffff',
    iconPosition: 'top',
    subFont: 'Kollektif',
    subFontSize: 16.1
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8aATTs6g/p38KqyBI8szvqdkLfqNe_g/edit?category=tACZCvjI6mE',
    fontFamily: 'Anaktoria',
    fontSize: 39.6,
    bold: null,
    italic: null,
    color: '#bd9479',
    backgroundColor: '#efeeec',
    iconColor: null,
    iconPosition: null,
    subFont: 'Arimo',
    subFontSize: 6.6
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8aeKJY5I/7fk5WIBGDUYUu2aUGrzPxg/edit?category=tACZCvjI6mE',
    fontFamily: 'Poppins',
    fontSize: 20.7,
    bold: true,
    italic: null,
    color: '#000000',
    backgroundColor: '#e1f4fd',
    iconColor: null,
    iconPosition: null,
    subFont: 'Moontime',
    subFontSize: 19.4
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8cJj9ABg/DtwajwMj4IZXakvAFaMY1g/edit?category=tACZCvjI6mE',
    fontFamily: 'Cormorant SC',
    fontSize: 42,
    bold: true,
    italic: null,
    color: '#3e2723',
    backgroundColor: '#ffffff',
    iconColor: '#9d674c',
    iconPosition: 'top',
    subFont: 'Garuda',
    subFontSize: 12
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8cI2Yz3Y/xI-owlPFBJbN2GXjDIe56w/edit?category=tACZCvjI6mE',
    fontFamily: 'Tenor Sans',
    fontSize: 28,
    bold: null,
    italic: null,
    color: '#640e23',
    backgroundColor: '#fde8e2',
    iconColor: '#640e23',
    iconPosition: 'top',
    subFont: 'Montserrat',
    subFontSize: 8
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8cLip0bQ/GJt5rhSZlAOSSD4Cxv1IEA/edit?category=tACZCvjI6mE',
    fontFamily: 'Kollektif',
    fontSize: 44.9,
    bold: true,
    italic: null,
    color: '#000000',
    backgroundColor: '#db663e',
    iconColor: '#ffffff',
    iconPosition: 'left',
    subFont: 'Kollektif',
    subFontSize: 6.8
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8cca3ako/yLv7VuauOZKqsu04i0z7lw/edit?category=tACZCvjI6mE',
    fontFamily: 'Cubao Wide',
    fontSize: 42,
    bold: null,
    italic: null,
    color: '#00f1d4',
    backgroundColor: '#271789',
    iconColor: null,
    iconPosition: null,
    subFont: 'Archivo',
    subFontSize: 16.4
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8cd93nOU/LSdCZhmfdGgokl1L5hMD3g/edit?category=tACZCvjI6mE',
    fontFamily: 'Hussar Ekologiczy',
    fontSize: 42,
    bold: null,
    italic: null,
    color: '#ab8742',
    backgroundColor: '#414b3b',
    iconColor: null,
    iconPosition: null,
    subFont: 'Montserrat',
    subFontSize: 10
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8eg25z_0/UMOBgvzkkr6OdBseLnA3Bg/edit?category=tACZCvjI6mE',
    fontFamily: 'Abys',
    fontSize: 71.8,
    bold: null,
    italic: null,
    color: '#f62a3c',
    backgroundColor: '#fcfaf4',
    iconColor: '#f62a3c',
    iconPosition: 'top',
    subFont: null,
    subFontSize: null
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8epfkts8/EyNjS9dIXGwAx4YEIzOgFw/edit?category=tACZCvjI6mE',
    fontFamily: 'Libre Baskerville',
    fontSize: 47.2,
    bold: true,
    italic: null,
    color: '#ffffff',
    backgroundColor: '#ed7f61',
    iconColor: '#ffffff',
    iconPosition: 'right',
    subFont: null,
    subFontSize: null
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8eoQoiZ8/gnffgN8PTdIJmGHmJtrndA/edit?category=tACZCvjI6mE',
    fontFamily: 'halant',
    fontSize: 32,
    bold: null,
    italic: null,
    color: '#050a30',
    backgroundColor: '#f4f6fc',
    iconColor: '#050a30',
    iconPosition: 'top',
    subFont: 'Raleway',
    subFontSize: 10
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8ehqebk0/yUicWYGRK-T94EayDUzD5A/edit?category=tACZCvjI6mE',
    fontFamily: 'Libre Baskerville',
    fontSize: 58,
    bold: null,
    italic: null,
    color: '#fbfcef',
    backgroundColor: '#3a4520',
    iconColor: '#975c10',
    iconPosition: 'bottom',
    subFont: 'Libre Baskerville',
    subFontSize: 14.5
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8egTtwpg/N5R3jcLoqL9Q1KCNMs1Axw/edit?category=tACZCvjI6mE',
    fontFamily: 'Libre Baskerville',
    fontSize: 36,
    bold: null,
    italic: null,
    color: '#ffffff',
    backgroundColor: '#29387e',
    iconColor: null,
    iconPosition: null,
    subFont: 'Libre Baskerville',
    subFontSize: 11.2
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8evnpw4s/FvC1cigVN7GVA8SOkhe-qA/edit?category=tACZCvjI6mE',
    fontFamily: 'Sniglet',
    fontSize: 36,
    bold: null,
    italic: null,
    color: '#231f20',
    backgroundColor: '#ffffff',
    iconColor: '#ffcb05',
    iconPosition: 'top',
    subFont: 'Sniglet',
    subFontSize: 12.5
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8e2IjqK8/jRau1WImI5xo5GUDFeEbcw/edit?category=tACZCvjI6mE',
    fontFamily: 'Adumu',
    fontSize: 64,
    bold: null,
    italic: null,
    color: '#603e94',
    backgroundColor: '#fac32a',
    iconColor: '#d7265d',
    iconPosition: 'top',
    subFont: 'League Spartan',
    subFontSize: 21
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8exfyX0M/-_J0HePiyd9kB3ac64UE9A/edit?category=tACZCvjI6mE',
    fontFamily: 'League Spartan',
    fontSize: 42,
    bold: null,
    italic: null,
    color: '#000000',
    backgroundColor: '#ffffff',
    iconColor: '#3134a1',
    iconPosition: 'top',
    subFont: 'Montserrat',
    subFontSize: 16
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8e4b_jw0/Kbvuj5OAdSwb_SnY_BCZ3w/edit?category=tACZCvjI6mE',
    fontFamily: 'Open Sans',
    fontSize: 36,
    bold: true,
    italic: null,
    color: '#000000',
    backgroundColor: '#ffffff',
    iconColor: '#0b00ff',
    iconPosition: 'top',
    subFont: null,
    subFontSize: null
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8ew7NU3Y/QGKG8LRCkFKN7vhBkI7gXQ/edit?category=tACZCvjI6mE',
    fontFamily: 'Norwester',
    fontSize: 40,
    bold: null,
    italic: null,
    color: '#000000',
    backgroundColor: '#fff300',
    iconColor: '#dd4031',
    iconPosition: 'left',
    subFont: 'Montserrat',
    subFontSize: 11.5
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8e393WR8/ZE00mOyy_ArqbOMKo3Q9yw/edit?category=tACZCvjI6mE',
    fontFamily: 'Oswald',
    fontSize: 52,
    bold: null,
    italic: null,
    color: '#444440',
    backgroundColor: '#fee2d4',
    iconColor: null,
    iconPosition: null,
    subFont: 'Montserrat',
    subFontSize: 12.5
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8e347Cww/7rZFb8QPGJfqoxQiEd45CQ/edit?category=tACZCvjI6mE',
    fontFamily: 'League Spartan',
    fontSize: 32,
    bold: null,
    italic: null,
    color: '#ffffff',
    backgroundColor: '#378842',
    iconColor: '#f6a724',
    iconPosition: 'top',
    subFont: 'Lustria',
    subFontSize: 8
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8ezcMJrc/PGrFMxY-bJ53YzFCyDLk4g/edit?category=tACZCvjI6mE',
    fontFamily: 'Aileron Heavy',
    fontSize: 36,
    bold: null,
    italic: null,
    color: '#d8644f',
    backgroundColor: '#ffffff',
    iconColor: '#f6bb25',
    iconPosition: 'left',
    subFont: null,
    subFontSize: null
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8e8nJE2E/D8vS0VoWsUJyina16ItxlA/edit?category=tACZCvjI6mE',
    fontFamily: 'Nunito',
    fontSize: 32,
    bold: null,
    italic: null,
    color: '#578e44',
    backgroundColor: '#e9ffb9',
    iconColor: '#623d0c',
    iconPosition: 'top',
    subFont: 'Nunito',
    subFontSize: 12
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8e8001EU/PDdBvt5QIHDfu8kd8XxOdw/edit?category=tACZCvjI6mE',
    fontFamily: 'Exo',
    fontSize: 38,
    bold: true,
    italic: true,
    color: '#ffffff',
    backgroundColor: '#724c9f',
    iconColor: '#100906',
    iconPosition: 'left',
    subFont: 'Exo',
    subFontSize: 10
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8e6j2dcU/XIfH4sfnlBZaI9Sw5kcgfw/edit?category=tACZCvjI6mE',
    fontFamily: 'Chewy',
    fontSize: 31,
    bold: null,
    italic: null,
    color: '#ffffff',
    backgroundColor: '#ff7bac',
    iconColor: '#f8ff2b',
    iconPosition: 'left',
    subFont: 'Quicksand',
    subFontSize: 9.3
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8exg96lk/7RalSD_VW1ntyuEG4cOwnA/edit?category=tACZCvjI6mE',
    fontFamily: 'Luckiest Guy',
    fontSize: 48,
    bold: null,
    italic: null,
    color: '#cd0046',
    backgroundColor: '#ffffff',
    iconColor: '#01949a',
    iconPosition: 'top',
    subFont: 'Quicksand',
    subFontSize: 16
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8e1YCofc/YzYhzIPi7-7fQvUcllwnOA/edit?category=tACZCvjI6mE',
    fontFamily: 'Marcellus',
    fontSize: 48,
    bold: null,
    italic: null,
    color: '#ffffff',
    backgroundColor: '#172243',
    iconColor: '#2fb783',
    iconPosition: 'top',
    subFont: 'Mardoto Regular',
    subFontSize: 14
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8ex3R0zs/cHk9Ss26M5yaUviIhGb3fw/edit?category=tACZCvjI6mE',
    fontFamily: 'Fira Sans',
    fontSize: 42,
    bold: null,
    italic: null,
    color: '#2f3b7e',
    backgroundColor: '#fdebec',
    iconColor: '#2f3b7e',
    iconPosition: 'top',
    subFont: 'Fira Sans',
    subFontSize: 14
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8e4KBWuE/9zSU2BEA0qoffhuqHcSNFQ/edit?category=tACZCvjI6mE',
    fontFamily: 'CMU Serif',
    fontSize: 45,
    bold: null,
    italic: null,
    color: '#ecfaff',
    backgroundColor: '#a55086',
    iconColor: '#ecfaff',
    iconPosition: 'left',
    subFont: 'Raleway',
    subFontSize: 14.6
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8e_PNGtY/J4A2ZvmMnTAQ_RnEO9v8nQ/edit?category=tACZCvjI6mE',
    fontFamily: 'Cormorant SC',
    fontSize: 42,
    bold: true,
    italic: null,
    color: '#343538',
    backgroundColor: '#ebebeb',
    iconColor: '#daa625',
    iconPosition: 'top',
    subFont: 'Cormorant SC',
    subFontSize: 14
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8e-2j4EA/j8FqNIwClcoY-YGeXaVOXw/edit?category=tACZCvjI6mE',
    fontFamily: 'Adigiana Toybox',
    fontSize: 43,
    bold: null,
    italic: null,
    color: '#8b38db',
    backgroundColor: '#fad54f',
    iconColor: '#dd2e44',
    iconPosition: 'left',
    subFont: 'Manjari',
    subFontSize: 14
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8e8fH-4A/F7VwtNR3n0NyG80Q4XbwKg/edit?category=tACZCvjI6mE',
    fontFamily: 'Sifonn',
    fontSize: 30,
    bold: null,
    italic: null,
    color: '#036079',
    backgroundColor: '#f5f8f2',
    iconColor: '#f7845c',
    iconPosition: 'top',
    subFont: 'Montserrat',
    subFontSize: 8
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8flUyCys/LqjE2FCxecczuXRTYarbKQ/edit?category=tACZCvjI6mE',
    fontFamily: 'Marta',
    fontSize: 29.8,
    bold: null,
    italic: true,
    color: '#5b6265',
    backgroundColor: '#fbd9ce',
    iconColor: '#1aaa9d',
    iconPosition: 'top',
    subFont: 'Montserrat',
    subFontSize: 10.1
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8frbfcpM/-lPvMCLhSdfIKPbRHteK6A/edit?category=tACZCvjI6mE',
    fontFamily: 'Bodoni FLF',
    fontSize: 41.7,
    bold: true,
    italic: null,
    color: '#525252',
    backgroundColor: '#fffcf3',
    iconColor: '#f6a5b8',
    iconPosition: 'left',
    subFont: 'Raleway',
    subFontSize: 11.9
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8fuP1HB8/ce03I0et0dxqvARsiMGFyw/edit?category=tACZCvjI6mE',
    fontFamily: 'Alike',
    fontSize: 20.9,
    bold: null,
    italic: null,
    color: '#716258',
    backgroundColor: '#f7eedf',
    iconColor: '#e97926',
    iconPosition: 'top',
    subFont: 'Alike',
    subFontSize: 8
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8fjmcJMw/f5KXbZ5uzF5B5axV8VP7sg/edit?category=tACZCvjI6mE',
    fontFamily: 'Quicksand',
    fontSize: 40.5,
    bold: true,
    italic: null,
    color: '#ffffff',
    backgroundColor: '#44d9e6',
    iconColor: null,
    iconPosition: null,
    subFont: 'Glacial Indifference',
    subFontSize: 10.1
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8fu19hTs/APy_1eHcVDRPZh22GF6kMw/edit?category=tACZCvjI6mE',
    fontFamily: 'Source sans pro',
    fontSize: 28,
    bold: null,
    italic: null,
    color: '#a8a8a8',
    backgroundColor: '#ffffff',
    iconColor: '#ff225c',
    iconPosition: 'left',
    subFont: 'Source sans pro',
    subFontSize: 11.9
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8focZNio/CEEkJrXh5ylnm9eOMlb7hw/edit?category=tACZCvjI6mE',
    fontFamily: 'Lato',
    fontSize: 24.3,
    bold: true,
    italic: null,
    color: '#ffffff',
    backgroundColor: '#7bd7c7',
    iconColor: '#ffffff',
    iconPosition: 'left',
    subFont: null,
    subFontSize: null
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8fkWbgCo/T8UbG-ULChXgy3lQq0HlTQ/edit?category=tACZCvjI6mE',
    fontFamily: 'League Spartan',
    fontSize: 32,
    bold: null,
    italic: null,
    color: '#ffffff',
    backgroundColor: '#d03f2d',
    iconColor: '#ffffff',
    iconPosition: 'left',
    subFont: 'Open Sans',
    subFontSize: 11.9
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8fibkl5Y/B7J1kZxV534f_mu7GPHBCQ/edit?category=tACZCvjI6mE',
    fontFamily: 'Aileron Heavy',
    fontSize: 60.4,
    bold: null,
    italic: null,
    color: '#d2c2b0',
    backgroundColor: '#1f1f24',
    iconColor: null,
    iconPosition: null,
    subFont: 'Aileron Regular',
    subFontSize: 10.1
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8fkaaRok/QmcQY76c3H0SLyDvh1fKxg/edit?category=tACZCvjI6mE',
    fontFamily: 'Fredoka One',
    fontSize: 27.8,
    bold: null,
    italic: null,
    color: '#ffffff',
    backgroundColor: '#2171cc',
    iconColor: '#ffffff',
    iconPosition: 'top',
    subFont: 'Quicksand',
    subFontSize: 11.9
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8fsjHeEg/JBLUdEXfIQ80VLgJns-6Sg/edit?category=tACZCvjI6mE',
    fontFamily: 'Aileron Regular',
    fontSize: 34.8,
    bold: true,
    italic: null,
    color: '#000000',
    backgroundColor: '#ffffff',
    iconColor: '#a7e66e',
    iconPosition: 'top',
    subFont: 'Aileron Regular',
    subFontSize: 11.9
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8f1fZjsA/T5OPZhRzeJJsgJXxx8I0Tw/edit?category=tACZCvjI6mE',
    fontFamily: 'Anaktoria',
    fontSize: 35.1,
    bold: null,
    italic: null,
    color: '#c25b08',
    backgroundColor: '#fff8ee',
    iconColor: '#cc6510',
    iconPosition: 'top',
    subFont: 'Avdira',
    subFontSize: 9
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8fy72f2I/m3oCuQwyrvi-VRIz9U73IA/edit?category=tACZCvjI6mE',
    fontFamily: 'Edo',
    fontSize: 31.8,
    bold: null,
    italic: null,
    color: '#232323',
    backgroundColor: '#ffffff',
    iconColor: '#8fe3d9',
    iconPosition: 'center',
    subFont: 'Montserrat',
    subFontSize: 8.9
  },
  {
    logoLink:
      'https://www.canva.com/design/DAD8f9lyTkc/xv0BsmJ5YRYbgcsm3O8GYA/edit?category=tACZCvjI6mE',
    fontFamily: 'Selima',
    fontSize: 63.6,
    bold: null,
    italic: null,
    color: '#373737',
    backgroundColor: '#ffe0cf',
    iconColor: '#373737',
    iconPosition: 'top',
    subFont: 'Montserrat',
    subFontSize: 11.9
  }
]
