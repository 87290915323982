export const domains = [
  'GoodExams',
  'offerral',
  'Sendext',
  'Fileers',
  'Pixeter',
  'Weartecs',
  'pentiq',
  'blacqs',
  'oxpace',
  'oSimply',
  'asktone',
  'datarify',
  'ripetrip',
  'pinsonic',
  'sharpana',
  'wishfive',
  'bloggive',
  'feetrust',
  'Moneyfits',
  'listenway',
  'textnight',
  'meetplate',
  'userlover',
  'busycheck',
  'checkvice',
  'planefits',
  'presshire',
  'ListenMeal',
  'seathi',
  'Cuttive',
  'eaziq',
  'lobiq',
  'squdi',
  'squse',
  'Appeck',
  'fublio',
  'danziq',
  'dleven',
  'fabliq',
  'presfy',
  'waytext',
  'deepare',
  'workips',
  'corpate',
  'mailbon',
  'tieride',
  'userwalk',
  'broadzip',
  'dateways',
  'letvalue',
  'minefits',
  'depology',
  'teamatter',
  'visitbaba',
  'teamsmail',
  'clearator',
  'informave',
  'nextusers',
  'referfeed',
  'tonsecure',
  'joinpools',
  'teachance',
  'ectiq',
  'mufiq',
  'zebfy',
  'apipdf',
  'curfly',
  'inible',
  'opmium',
  'bubblq',
  'espead',
  'govace',
  'laffir',
  'pottiv',
  'zeptiv',
  'zultix',
  'genitis',
  'genment',
  'mealvoo',
  'taxbuild',
  'zerofits',
  'carolate',
  'littable',
  'maintion',
  'rollhunt',
  'tailable',
  'viewfork',
  'calminio',
  'histchat',
  'selfcause',
  'endreader',
  'examinner',
  'logicalon',
  'checkious',
  'invercent',
  'namelearn',
  'ridebride',
  'singlance',
  'learnimage',
  'rideground',
  'BuildPager',
  'solutioncloset',
  'snordy',
  'primeNeasy',
  'putlix',
  'petbal',
  'TeamMately',
  'Sightax',
  'motherhuman',
  'amoniaq',
  'bumshot',
  'cleaq',
  'deskd',
  'linkili',
  'onetimecourtesy',
  'qceve',
  'refermoi',
  'sqach',
  'tradealley',
  'WEDDEXP',
  'samunity',
  'verifylook',
  'mixtract',
  'isFare'
]
