import React, { useState } from 'react'
import PropTypes from 'prop-types'

import styles from './CardResult.module.css'
import { like, dislike, listen, dislikeFill, likeFill } from './icons'

import Checkbox from '../Checkbox'

function classNames() {
  return Object.keys(arguments)
    .map((key) => arguments[key])
    .join(' ')
}

function CardResult({ domain, tld, ...props }) {
  const children = props.children

  const [isSelected, setSelected] = useState(false)
  const [isLiked, setLike] = useState(false)
  const [isDisliked, setDislike] = useState(false)

  function _onSelect(e) {
    setSelected(e.target.checked)
  }
  function _handleEnter(e) {
    if (e.key === 'Enter') {
      setSelected(!isSelected)
    }
  }
  function _like() {
    setLike(!isLiked)
    if (isDisliked) {
      setDislike(false)
    }
  }
  function _dislike() {
    setDislike(!isDisliked)
    if (isLiked) {
      setLike(false)
    }
  }
  return (
    <div
      className={classNames(styles.card, isSelected ? styles.cardSelected : '')}
      tabIndex='0'
    >
      <div className={classNames(styles.mainContainer)}>
        <div className={classNames(styles.select)}>
          <Checkbox
            isSelected={isSelected}
            handleEnter={_handleEnter}
            onCheckboxChange={_onSelect}
          />
        </div>
        <div className={classNames(styles.logoContainer)}>{children}</div>
        <div
          className={classNames(
            styles.actionsContainer,
            isSelected ? styles.actionsContainerSelected : ''
          )}
        >
          <h3 className={classNames(styles.domain)}>
            <span className={classNames(styles.domainName)}>{domain}</span>
            <span className={classNames(styles.tld)}>.{tld}</span>
          </h3>
          <div className={classNames(styles.actions)}>
            <button className={[styles.button, styles.btn].join(' ')}>
              <span className={styles.hidden}>listen</span>
              <span className={styles['btn_content']} tabIndex='-1'>
                {listen}
              </span>
            </button>
            <button
              className={[
                styles.button,
                styles.btn,
                isLiked ? '' : styles.like
              ].join(' ')}
              onClick={_like}
            >
              <span className={styles.hidden}>like</span>
              <span className={styles['btn_content']} tabIndex='-1'>
                {isLiked ? likeFill : like}
              </span>
            </button>
            <button
              className={[styles.button, styles.btn].join(' ')}
              onClick={_dislike}
            >
              <span className={styles.hidden}>dislike</span>
              <span className={styles['btn_content']} tabIndex='-1'>
                {isDisliked ? dislikeFill : dislike}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

CardResult.propTypes = {
  domain: PropTypes.string.isRequired,
  tld: PropTypes.string.isRequired,
  children: PropTypes.any
}

export default CardResult
