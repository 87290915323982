import React from 'react'
import { Link } from 'react-router-dom'

import { icons } from '../contents/icons'
import { types } from '../contents/iconTypes'
import { domains } from '../contents/domains'
import Logo from '../components/Logo'
import LogoWrapper from '../components/LogoWrapper'

import './App.css'

function randomNumberBetween0And(number) {
  return Math.floor(Math.random() * (number + 1))
}

function App() {
  const description = 'description'
  return (
    <>
      <div className='to-results'>
        <Link className='link' to='/results'>
          View new result format (Card)
        </Link>
      </div>
      <div className='app'>
        {domains.map((domain, index) => {
          const typeIndex = randomNumberBetween0And(types.length - 1)
          const type = types[typeIndex]
          const iconIndex = randomNumberBetween0And(icons.length - 1)
          const icon = icons[iconIndex]
          return (
            <div className='container' key={index}>
              <LogoWrapper>
                <Logo
                  icon={icon}
                  title={domain}
                  type={type}
                  description={description}
                />
              </LogoWrapper>
              <Link to={`/domain/${domain}`} className='link'>
                <p className='domain-name'>{domain}</p>
              </Link>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default App
