import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Back, { Empty } from '../../components/Back'
import { icons } from '../../contents/icons'
import './style.css'

function Domain({ ...props }) {
  const domain = props.match.params.domain
  return (
    <div className='main-container'>
      <div className='title-container'>
        <Back />
        <span
          style={{
            margin: 'auto'
          }}
        >
          <h2 className='title'>{domain}</h2>
          <p className='description'>Choose the Icon</p>
        </span>
        <Empty />
      </div>
      <div className='icon-container'>
        {icons.map((item, index) => {
          return (
            <Link
              key={index}
              className='link'
              to={`/domain/${domain}/${index}`}
            >
              <div className='icon'>{item}</div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

Domain.propTypes = {
  match: PropTypes.object
}

export default Domain
