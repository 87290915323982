import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  transition: box-shadow ease 0.3s, transform ease 0.3s;
  border-radius: 15px;
  overflow: hidden;
  width: 400px;
  height: 400px;
  :hover {
    box-shadow: 0px 1px 10px 0px #9a9a9a8a;
    transform: scale(1.1);
    z-index: 10;
  }
  @media screen and (max-width: 450px) {
    transform: scale(${(props) => props.containerScale - 0.2});
  }
`

function LogoWrapper({ ...props }) {
  return <Wrapper>{props.children}</Wrapper>
}

LogoWrapper.propTypes = {
  children: PropTypes.any
}
export default LogoWrapper
