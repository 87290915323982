import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const MainContainer = styled.div`
  all: initial;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  transition: box-shadow ease 0.3s, transform ease 0.3s;
  transform: scale(${(props) => props.containerScale});
  position: relative;
`
const IconAndDomainContainer = styled.div`
  all: initial;
  display: flex;
  flex-direction: ${(props) => {
    if (props.iconPosition === 'left') {
      return 'row'
    }
    if (props.iconPosition === 'right') {
      return 'row-reverse'
    }
    if (props.iconPosition === 'bottom') {
      return 'column-reverse'
    }
    return 'column'
  }};
  justify-content: center;
  align-items: center;
`
const IconContainer = styled.div`
  all: initial;
  fill: ${(props) => props.color};
  svg {
    width: ${(props) => {
    if (props.isAside) {
      return '100px'
    }
    if (props.isCenter) {
      return '350px'
    }
    return '180px'
  }};
    height: ${(props) => {
    if (props.isAside) {
      return '100px'
    }
    if (props.isCenter) {
      return '350px'
    }
    return '180px'
  }};
  }
  ${(props) =>
    props.isCenter
      ? `position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;`
      : ''}
`
const DomainName = styled.p`
  all: initial;
  color: ${(props) => props.color};
  font-family: ${(props) => props.fontFamily};
  font-size: ${(props) => props.fontSize}px;
  z-index: 1;
  line-height: 1.4em;
  ${(props) => (props.isItalic ? `font-style: italic;` : '')}
  ${(props) => (props.isBold ? `font-weight:  bold;` : '')}
`

const Description = styled.p`
  all: initial;
  color: ${(props) => props.color};
  font-family: ${(props) => props.fontFamily};
  font-size: ${(props) => props.fontSize}px;
  z-index: 1;
  line-height: 1.4em;
`

function Logo({ type, icon, title, description }) {
  const {
    fontFamily,
    fontSize,
    bold,
    italic,
    color,
    backgroundColor,
    iconColor,
    iconPosition,
    subFont,
    subFontSize
  } = type

  return (
    <MainContainer backgroundColor={backgroundColor}>
      <IconAndDomainContainer iconPosition={iconPosition}>
        {!!iconPosition && (
          <IconContainer
            color={iconColor}
            isCenter={iconPosition === 'center'}
            isAside={iconPosition === 'left' || iconPosition === 'right'}
          >
            {icon}
          </IconContainer>
        )}
        <DomainName
          color={color}
          fontFamily={fontFamily}
          fontSize={fontSize}
          isBold={bold}
          isItalic={italic}
        >
          {title}
        </DomainName>
      </IconAndDomainContainer>
      {!!subFont && (
        <Description fontFamily={subFont} fontSize={subFontSize} color={color}>
          {description}
        </Description>
      )}
    </MainContainer>
  )
}

Logo.propTypes = {
  type: PropTypes.object,
  icon: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string
}
Logo.defaultProps = {}

export default Logo
